<template>
  <div class="flex flex-col gap-10">
    <UiDialogTitle class="text-2xl font-semibold">{{ t("title") }}</UiDialogTitle>

    <div class="flex flex-col gap-6">
      <div class="flex flex-col gap-3">
        <AuthSocialLogin />
      </div>

      <UiTextDivider>{{ t("or") }}</UiTextDivider>

      <AuthManager
        :recaptcha="{ enabled: true, action: 'login' }"
        :is-signup="false"
        :loading="loginMutation.isPending.value"
        :hide-ghost-link="hideGhostLink"
        @onSubmit="submit"
      />
    </div>

    <i18n-t class="font-light text-black/50 md:text-center" tag="span" keypath="not_signed_up">
      <template #action>
        <UiButton class="h-fit p-0 text-base font-medium" variant="link" @click="changeAuthPage('signup')">
          {{ t("signup") }}
        </UiButton>
      </template>
    </i18n-t>
  </div>
</template>

<script setup lang="ts">
import { useMutation } from "@tanstack/vue-query"
import { HTTPError } from "ky"

import { LoginCredentials } from "@finq/app-base/composables/services/auth.service"

const props = defineProps<{ hideGhostLink?: boolean }>()
const emit = defineEmits(["userNavigated", "onClose"])

const { t } = useI18n({ useScope: "local" })
const { t: $t } = useI18n({ useScope: "global" })

const user = useUser()
const popup = usePopup()
const gtm = useGtm()

const apiError = useAuthApiError()
const { changeAuthPage, handleSuccess } = useLogin()
const { formState, clearFormState } = useAuthManager()
const { executeReCaptcha } = useReCaptchaHandler()

const handleApiErrorMessages = (errorCode: number) => {
  let authApiError = { code: errorCode }

  switch (errorCode) {
    case 400:
      Object.assign(authApiError, {
        message: "authentication.apiErrors.bad_email",
        field: "email",
      })
      break

    case 403:
      Object.assign(authApiError, {
        message: "authentication.apiErrors.invalid_mail_or_pass",
      })
      break

    default:
      Object.assign(authApiError, {
        message: "authentication.apiErrors.general",
      })
      break
  }

  apiError.error.value = authApiError as AuthApiError
}

const handleApiErrorPopups = (errorCode: number) => {
  switch (errorCode) {
    case 401:
      return popup.open({ title: $t("authentication.apiErrors.server_error") })

    case 406:
      // If code is Not Acceptable (406) it means the user is signed up but not verified
      // so we redirect to the verify page
      return changeAuthPage("verify")

    case 403:
      return null

    case 429:
      return popup.open({
        title: $t("authentication.blocked.header_title"),
        content: $t("authentication.apiErrors.too_many_tries"),
      })

    case 500:
      return popup.open({ title: $t("authentication.apiErrors.general") })
  }
}

const handleLoginApiSuccess = (user: any) => {
  gtm.push({ event: "set_user_data", user_id: user?.id, email: formState.email })

  handleSuccess()
  clearFormState()
}

const handleLoginApiError = (error: HTTPError) => {
  if (!error?.response || !(error instanceof HTTPError)) return

  const code = error.response.status

  handleApiErrorMessages(code)
  handleApiErrorPopups(code)
}

const loginWithRecaptcha = async (credentials: LoginCredentials) => {
  const recaptchaToken = await executeReCaptcha(RecaptchaAction.LOGIN)
  return AuthService.loginWithRecaptcha(credentials, recaptchaToken)
}

const loginMutation = useMutation({
  mutationFn: loginWithRecaptcha,
  onSuccess: handleLoginApiSuccess,
  onError: handleLoginApiError,
})

const submit = async (credentials: LoginCredentials) => {
  gtm.push({ event: "login_attempt", method: "user-password", email: credentials.email })

  loginMutation.mutate(credentials)
}
</script>

<i18n lang="json">
{
  "he": {
    "title": "כניסה",
    "or": "או",
    "not_signed_up": "חדשים ב־FINQ? {action}",
    "signup": "הרשמה"
  },
  "en": {
    "title": "Log in",
    "or": "or",
    "not_signed_up": "Don’t have an account? {action}",
    "signup": "Sign up"
  }
}
</i18n>

<template>
  <UiButton
    class="gap-xxs p-xxs relative h-auto items-center text-base leading-none"
    variant="alternative"
    @click="googleLogin"
    :disabled="disabled"
    :id="genClickId('auth', isSignup ? 'signup' : 'login', 'google_social_button')"
  >
    <UiNuxtIcon class="start-xxs absolute top-1/2 -translate-y-1/2" filled :size="16" name="socials/google" />
    {{ buttonText + " Google" }}
  </UiButton>

  <p class="text-destructive whitespace-pre-line text-sm" v-if="googleCannotLoginWithWebview">
    {{ t("cannot_login") }}
  </p>

  <UiButton
    class="gap-xxs p-xxs relative h-auto items-center text-base leading-none"
    variant="alternative"
    @click="facebookLogin"
    :disabled="disabled"
    :id="genClickId('auth', isSignup ? 'signup' : 'login', 'facebook_social_button')"
  >
    <UiNuxtIcon
      class="start-xxs absolute top-1/2 -translate-y-1/2"
      filled
      :size="16"
      name="socials/facebook"
    />
    {{ buttonText + " Facebook" }}
  </UiButton>
</template>

<script setup lang="ts">
import { DiscriminatedDataLayerArg } from "@finq/app-base/composables/analytics/useGtm"

import { useSocialLoginUtils } from "./useSocialLoginUtils"

const props = withDefaults(defineProps<{ isSignup?: boolean; disabled?: boolean }>(), {})
const emit = defineEmits(["on-loading", "onLogin"])

const { t } = useI18n({ useScope: "local" })
const { t: $t } = useI18n()
const gtm = useGtm()
const { getSocialAuthCallback } = useSocialLoginUtils()
const isWebView = useIsWebView()

const googleCannotLoginWithWebview = ref(false)

const buttonText = computed(() => {
  return props.isSignup
    ? $t("authentication.signup.buttons.register_with")
    : $t("authentication.login.buttons.login_with")
})

async function facebookLogin() {
  emit("on-loading", true)

  // When the user clicks on button the event 'signup_attempt' should trigger
  gtmSocialSignupEvent("facebook")
  await AuthService.loginWithSocialPopup("facebook", getSocialAuthCallback)
}

async function googleLogin() {
  if (isWebView.value) {
    googleCannotLoginWithWebview.value = true
    return
  }

  emit("on-loading", true)

  // When the user clicks on button the event 'signup_attempt' should trigger
  gtmSocialSignupEvent("google-oauth2")
  await AuthService.loginWithSocialPopup("google-oauth2", getSocialAuthCallback)
}

function gtmSocialSignupEvent(socialName: string) {
  let event = props.isSignup ? "signup_attempt" : "login_attempt"

  gtm.push({
    event: event,
    method: socialName.toLowerCase(),
  } as DiscriminatedDataLayerArg)
}
</script>

<i18n lang="json">
{
  "he": {
    "cannot_login": "ההרשמה נכשלה, הדפדפן אינו עומד בדרישות האבטחה.\n נסה שוב עם דפדפן אחר."
  },
  "en": {
    "cannot_login": "Please open this page on your default browser to sign in with Google"
  }
}
</i18n>

<template>
  <div class="flex flex-col gap-10 md:min-h-64 md:pt-10">
    <UiDialogHeader class="mb-0 flex flex-col gap-4 p-0">
      <UiDialogTitle class="text-2xl font-semibold">{{ t("title") }}</UiDialogTitle>
      <UiDialogDescription class="text-sm/4 font-light text-black/50">{{
        t("description")
      }}</UiDialogDescription>
      <b class="block text-sm/4 font-medium">{{ formState.email }}</b>
    </UiDialogHeader>

    <div class="flex items-center">
      <i18n-t class="font-light text-black/50" tag="span" keypath="verification_not_sent">
        <template #resend>
          <UiButton
            class="h-fit p-0 text-base font-medium text-inherit underline"
            variant="link"
            :disabled="forgotPasswordMutation.isSuccess.value || forgotPasswordMutation.isPending.value"
            @click="formState.email && forgotPasswordMutation.mutate({ email: formState.email })"
          >
            {{ forgotPasswordMutation.isSuccess.value ? t("sent") : t("resend") }}
          </UiButton>
        </template>
      </i18n-t>

      <UiTransition>
        <UiSpinner class="ms-2" v-if="forgotPasswordMutation.isPending.value" size="xs" />
      </UiTransition>
    </div>

    <Teleport v-if="isMounted" to="#auth-widget-nav-slot" :disabled="!isMobile">
      <UiButton
        class="md:text-primary group h-fit max-h-6 w-fit p-0 text-base/6 font-medium text-black/50"
        variant="link"
        @click="navigateBack({ fallback: 'forgotpassword' })"
      >
        <LucideChevronLeft
          class="transition-all group-hover:-translate-x-1 rtl:rotate-180 rtl:group-hover:translate-x-1"
          :size="20"
        />
        {{ $t("common.back") }}
      </UiButton>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { LucideChevronLeft } from "lucide-vue-next"

const isMounted = useMounted()
const { isMobile } = useDisplay()
const { navigateBack } = useLogin()
const { t } = useI18n({ useScope: "local" })
const { formState, clearFormState } = useAuthManager()

const { forgotPasswordMutation } = useForgotPasswordMutation()

// onUnmounted(() => formState.email && clearFormState())
</script>

<i18n lang="json">
{
  "he": {
    "title": "שלחנו לך מייל",
    "description": "שלחנו לינק לאיפוס הסיסמה לכתובת:",
    "cta_text": "שלחו לי לינק",
    "verification_not_sent": "לא קיבלת לינק? {resend}",
    "resend": "שלחו שוב",
    "sent": "נשלח"
  },
  "en": {
    "title": "Check your email ",
    "description": "We’ve set a link to resetting your password to: ",
    "cta_text": "Send me the link",
    "verification_not_sent": "Didn’t get a link? {resend}",
    "resend": "Resend",
    "sent": "Sent"
  }
}
</i18n>

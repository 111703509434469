<template>
  <UiDialogBasic
    :open="isOpen"
    @update:open="(isOpen) => !isOpen && handleClose()"
    backdrop="white"
    :classes="{
      root: 'z-60 flex h-full flex-1 bg-white shadow-[0_1px_4px_0_rgba(0,_0,_0,_0.08)] md:h-fit',
      panel: 'p-0',
    }"
  >
    <div class="max-w-large relative flex flex-1 flex-col rounded md:h-fit">
      <span class="absolute start-5 top-5 z-[60] md:start-4 md:top-4" id="auth-widget-nav-slot" />

      <div
        class="flex flex-col overflow-hidden p-24 py-20 md:px-5 md:pb-10 md:pt-12"
        v-auto-animate="autoAnimateFade"
      >
        <component :is="is" @onClose="handleClose" />
      </div>
    </div>
  </UiDialogBasic>
</template>

<script setup lang="ts">
import type { TAuthComponentsMap } from "@finq/app-base/composables/useLogin"

import { useSocialLoginUtils } from "./useSocialLoginUtils"

const emit = defineEmits(["onCLose", "userNavigated"])

const { isAuthenticated } = useUser()
const router = useRouterUtils()
const { isOpen, currPage, handleClose, getAuthTokenFromUrl, handleSuccess, changeAuthPage } = useLogin()
const { getSocialAuthCallback } = useSocialLoginUtils()
const { t } = useI18n({ useScope: "global" })

const AuthComponentsMap: Record<TAuthComponentsMap, any> = {
  login: resolveComponent("AuthScreensLogin"),
  forgotpassword: resolveComponent("AuthScreensForgotPassword"),
  signup: resolveComponent("AuthScreensSignupLobby"),
  signupform: resolveComponent("AuthScreensSignupForm"),
  passwordresetawait: resolveComponent("AuthScreensConfirmationWait"),
  verify: resolveComponent("AuthScreensVerify"),
}

const is = computed(() => {
  const currentPage = currPage.value
  if (currentPage) return AuthComponentsMap[currentPage]

  return null
})

const { clearFormState } = useAuthManager()

onUnmounted(() => clearFormState())

onMounted(async () => {
  if (isAuthenticated.value) return handleClose()

  const callbackData = getAuthTokenFromUrl()

  if (callbackData?.access_token) {
    await router.replace({ hash: "" })
    return AuthService.getUserFromSocialLogin(callbackData.access_token)
      .then((res) => getSocialAuthCallback(null, res))
      .catch((err) => getSocialAuthCallback(err))
  }
})
</script>

<style lang="scss">
.auth-widget__navbar {
  &-container {
    padding: theme.$spacing-s theme.$spacing-m;
    align-items: flex-start;
  }

  @include theme.media(">md") {
    .navbar__icon--hidden {
      display: none;
    }
  }

  @include theme.media("<md") {
    &-container {
      padding: theme.$spacing-xxs theme.$spacing-s;
    }
  }
}

.auth-widget--min-height {
  @screen md {
    min-height: 450px;
  }
}
</style>

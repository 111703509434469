<template>
  <div
    :class="cn('flex items-center gap-3 whitespace-pre text-sm font-medium text-black/50', props.class)"
    role="separator"
  >
    <span class="bg-border h-px flex-grow" />

    <UiLink class="hover:underline" v-if="to" :to="to">
      <slot>{{ text }}</slot>
    </UiLink>
    <span v-else>
      <slot>{{ text }}</slot>
    </span>

    <span class="bg-border h-px flex-grow" />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  to?: TypedRouteProps
  text?: string
  class?: ClassValue
}>()
</script>

export function useSocialLoginUtils() {
  const { changeAuthPage, handleSuccess } = useLogin()
  const { t } = useI18n()
  const gtm = useGtm()
  const popup = usePopup()

  /** @param code - Recieves an error code and decide which error to display according to error code */
  function handleApiError(code: number) {
    switch (code) {
      case 400:
        return t("authentication.apiErrors.bad_email")

      case 401:
        return t("authentication.apiErrors.server_error")

      case 403:
        return t("authentication.apiErrors.invalid_mail_or_pass")

      case 406:
        // If code is Not Acceptable (406) it means the user is signed up but not verified
        // so we redirect to the verify page
        changeAuthPage("verify")
        return t("authentication.apiErrors.unverified_mail")

      case 429:
        return t("authentication.apiErrors.too_many_tries")

      default:
        return t("authentication.apiErrors.general")
    }
  }

  function getSocialAuthCallback(err: HTTPError | null | unknown = null, user: User | null = null) {
    if (err) {
      const { response } = err as HTTPError

      if (!response) return

      // if (response.status === 403) forgotpassError = true;
      popup.open({ content: handleApiError(response.status) })
    } else if (user) {
      gtm.push({ event: "set_user_data", user_id: user.id, email: user.email })

      handleSuccess()
    }
  }

  return {
    handleApiError,
    getSocialAuthCallback,
  }
}

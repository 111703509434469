/**
 * Detects if the application is running in a WebView environment (embedded browser)
 * @returns Ref indicating whether the current environment is a WebView
 */
export function useIsWebView() {
  const isWebView = ref(false)

  tryOnMounted(() => {
    // Skip detection if not running in client environment
    if (!import.meta.client) return

    // WebView detection patterns for different platforms
    const webViewPatterns = {
      GENERIC_WEBVIEW: "WebView",
      IOS_WEBVIEW: "(iPhone|iPod|iPad)(?!.*Safari)",
      ANDROID_MODERN: "Android.*(;\\s+wv|Version/\\d.\\d\\s+Chrome/\\d+(\\.0){3})",
      ANDROID_LEGACY: "Linux; U; Android",
    }

    // Combine all patterns into a single regular expression
    const expression = new RegExp(`(${Object.values(webViewPatterns).join("|")})`, "ig")

    // Check if the user agent matches any WebView pattern
    isWebView.value = !!window.navigator.userAgent.match(expression)
  })

  return isWebView
}

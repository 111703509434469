<template>
  <div class="flex flex-col gap-10 md:min-h-64 md:pt-10">
    <UiDialogHeader class="mb-0 flex flex-col gap-4 p-0">
      <UiDialogTitle class="text-2xl font-semibold">{{ t("title") }}</UiDialogTitle>
      <UiDialogDescription class="text-sm/4 font-light text-black/50">{{
        t("description")
      }}</UiDialogDescription>
    </UiDialogHeader>

    <form
      class="flex flex-col gap-4 md:mt-auto"
      @submit.prevent="formState.email && forgotPasswordMutation.mutate({ email: formState.email })"
    >
      <UiInput
        v-model:model-value="formState.email"
        name="email"
        :variant="emailErrors.length > 0 ? 'error' : 'default'"
        :placeholder="$t('authentication.login.inputs.email')"
        :errors="emailErrors"
        :hide-details="!emailErrors.length"
        autocomplete="email"
        :required="true"
        maxlength="80"
        size="adaptive"
        type="email"
        :classes="{ label: 'text-muted-foreground font-semibold' }"
        outlined
        @blur="v$.email.$touch()"
        @input="v$.email.$reset()"
      />

      <UiButton type="submit" :loading="forgotPasswordMutation.isPending.value" :disabled="v$.$invalid">
        {{ t("cta_text") }}
      </UiButton>
    </form>

    <Teleport v-if="isMounted" to="#auth-widget-nav-slot" :disabled="!isMobile">
      <UiButton
        class="md:text-primary group h-fit max-h-6 w-fit p-0 text-base/6 font-medium text-black/50"
        variant="link"
        @click="navigateBack({ fallback: 'login' })"
      >
        <LucideChevronLeft
          class="transition-all group-hover:-translate-x-1 rtl:rotate-180 rtl:group-hover:translate-x-1"
          :size="20"
        />
        {{ $t("common.back") }}
      </UiButton>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import useVuelidate from "@vuelidate/core"
import { email, minLength, required } from "@vuelidate/validators"
import { LucideChevronLeft } from "lucide-vue-next"

const isMounted = useMounted()

const user = useUser()
const { isMobile } = useDisplay()

const { navigateBack } = useLogin()
const { formState } = useAuthManager()
const { t: $t } = useI18n({ useScope: "global" })
const { t } = useI18n({ useScope: "local" })

const rules = computed(() => {
  return {
    email: { required, minLength: minLength(7), email },
  }
})

const v$ = useVuelidate(rules, formState)

const emailErrors = computed(() => {
  const errors: string[] = []
  const $v = v$.value.email

  if (!$v?.$dirty) return errors

  $v.required.$invalid && errors.push($t("authentication.login.inputs.email_required"))
  $v.email.$invalid && errors.push($t("kyc_common.validation_errors.email_field"))

  return errors
})

onMounted(() => {
  if (user.user.value?.email) formState.email = user.user.value?.email
})

const { forgotPasswordMutation } = useForgotPasswordMutation()
</script>

<i18n lang="json">
{
  "he": {
    "title": "איפוס סיסמה",
    "description": "הכל טוב, נשלח לך לינק לאיפוס הסיסמה.",
    "cta_text": "שלחו לי לינק"
  },
  "en": {
    "title": "Reset Password",
    "description": "No worries. A link for resetting your password will be sent to your email shortly.",
    "cta_text": "Send reset link"
  }
}
</i18n>
